import { Injectable } from '@angular/core';
import {AuthService, User} from "@auth0/auth0-angular";
import {lastValueFrom} from "rxjs";
import { Utente } from '../models/utenti/utente.model';
import { UtentiService } from './latitude/utenti/utenti.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userData: Utente;
  public isSuperAdmin:boolean   = false;
  public isAdmin:boolean        = false;
  public isUtente:boolean        = false;

  public ruoloStr: string;

  constructor(private auth: AuthService,
    private utenti: UtentiService,
    private logger: NGXLogger,

    ) {
    this.userData = null;
  }

  async loadSetUserInformation (user: User) {
  /*   this.logger.info("* loadSetUserInformation *");
    this.logger.info(user);
 */
    const idAuth0:string = user.sub.slice(user.sub.indexOf('|')+1);
    //const utente = await lastValueFrom(this.utenti.getByIdAuth0(idAuth0));
   // this.setUser(utente);


    this.ruoloStr = user["https://latitude.map-hosting.it/roles"][0];
    this.setRoleFromAuth0(this.ruoloStr);
    localStorage.setItem("isLoggedIn", "true");
    localStorage.setItem("ruolo", this.ruoloStr);
    localStorage.setItem("idUtente", user.sub.slice(user.sub.indexOf('|') + 1));
    localStorage.setItem("utente", user.name);

  }

  setUser(user: Utente) {
    this.userData = user;
    //this.logger.info("* setUser *");
  }

  public setRoleFromAuth0(role:string){
   // this.logger.info("* setRoleFromAuth0: "+ role);

    if(role==='SUPERADMIN'){
        this.isSuperAdmin = true;
    }else if(role==='ADMIN'){
        this.isAdmin = true;
    }else if(role==='UTENTE'){
        this.isUtente = true;
    }
  }


  getUser(): Utente {
    return this.userData;
  }
}
