import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppLayoutComponent } from "./layout/app.layout.component";
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NotfoundComponent } from './layout/components/error/notfound/notfound.component';
import { UnderConstructionComponent } from './layout/components/error/under-construction/under_construction.component';


@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppLayoutComponent,
                 children: [
                    { path: '', loadChildren: () => import('./layout/components/dashboard/dashboard.module').then(m => m.DashboardModule) },
                    { path: 'error', loadChildren: () => import('./layout/components/error/error.module').then(m => m.ErrorModule) },
                    { path: 'cdu', loadChildren: () => import('./layout/components/cdu/cdu.module').then(m => m.CDUModule) },
                    { path: 'crediti', loadChildren: () => import('./layout/components/crediti/crediti.module').then(m => m.CreditiModule) },
                    { path: 'geocoder', loadChildren: () => import('./layout/components/geocoder/geocoder.module').then(m => m.GeocoderModule) },
                    { path: 'utenti', loadChildren: () => import('./layout/components/utenti/utenti.module').then(m => m.UtentiModule) },
                    { path: 'catasto', loadChildren: () => import('./layout/components/catasto/catasto.module').then(m => m.CatastoModule) },
                    { path: 'visualizzatore', loadChildren: () => import('./layout/components/visualizzatore/visualizzatore.module').then(m => m.VisualizzatoreModule) },
                    { path: 'api', loadChildren: () => import('./layout/components/api/api.module').then(m => m.APIModule) },
                ]
            },
              { path: '**', redirectTo: '/error/underconstruction' },
        ], {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            onSameUrlNavigation: 'reload'
        })
    ],
    exports: [RouterModule],
    providers: [
        {provide: LocationStrategy, useClass: PathLocationStrategy}
      ],
})
export class AppRoutingModule {
}
