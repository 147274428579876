import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import { environment } from 'src/environments/environment';
import { AuthService } from "@auth0/auth0-angular";
import { NGXLogger } from 'ngx-logger';
import { UserService } from '../services/user.service';
import { SupportService } from '../services/support.service';
import { CreditoService } from '../services/latitude/credito/credito.service';
import {onSnapshot} from "firebase/firestore";
import { Credito_Firestore } from '../models/firestore/creditoFirestore.model';
import { AngularFirestore } from '@angular/fire/compat/firestore';
@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit{

    items!: MenuItem[];
    protected appVersion:String=environment.version;
    severityCredito:string;
    private creditoFromFirestore:Credito_Firestore;

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    protected creditChip: string = "";
    protected creditChipColor:String = '';

    constructor(
        public layoutService: LayoutService,
        public authService: AuthService,
        private userService:UserService,
        private logger: NGXLogger,
        private supportService:SupportService,
        private creditoService:CreditoService,
        private firestore: AngularFirestore,

        ) {

         }

    ngOnInit(): void {
        this.logger.info("INIT @ TOPBAR " );


        this.creditChipColor = "#7DCE82";

        this.getCreditoUtente();

    }

    async getCreditoUtente(){
        this.supportService.waitForStorageValue("idUtente").then(idUtente => {
            if(localStorage.getItem("ruolo") === 'UTENTE'){
               // this.logger.info("getCreditoUtente @ TOPBAR per utente "+ localStorage.getItem("idUtente") + " RUOLO "+ localStorage.getItem("ruolo") + " vado su " + environment.firebase.latitude.collection_crediti);
                const collectionRef = this.firestore.collection(environment.firebase.latitude.collection_crediti).ref;
                const query = collectionRef.where("idAuth0", "==", localStorage.getItem("idUtente"));

                onSnapshot(collectionRef, (qs) => {

                    qs.docs.map((item) => {
                        this.creditoFromFirestore =  item.data() as Credito_Firestore;
                        if(this.creditoFromFirestore.numero_totale<=10){
                            this.severityCredito = "danger";
                        }else if(this.creditoFromFirestore.numero_totale>10 && this.creditoFromFirestore.numero_totale<=25){
                            this.severityCredito = "warning";
                        }else{
                            this.severityCredito ="success";
                        }
                        this.creditChip = "CREDITI DISPONIBILI: "+ this.creditoFromFirestore.numero_totale;
                    });
                });

            }

        });
    }

    openGestioneCredito(){}

    login() {
        this.logger.info("LOGIN @ TOPBAR " );
        this.authService.loginWithRedirect().subscribe();
      }

      logout() {

        localStorage.setItem("idUtente", null);
        localStorage.setItem("utente", null);
        localStorage.setItem("ruolo", null);
        localStorage.setItem("isLoggedIn", "false");
        this.authService.logout({ logoutParams: { returnTo: document.location.origin } });
    }
}
