import { Component, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { PrimeNGConfig } from 'primeng/api';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    constructor(
        private primengConfig: PrimeNGConfig,
        private logger: NGXLogger ) { }

    ngOnInit() {
        //this.logger.info("*ONINIT @ AppComponent");
        this.primengConfig.ripple = true;
    }
}
