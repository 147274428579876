import { NgModule } from '@angular/core';
import { CommonModule, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';

//PARTE DA PULIRE!
import { ProductService } from './demo/service/product.service';
import { CountryService } from './demo/service/country.service';
import { CustomerService } from './demo/service/customer.service';
import { EventService } from './demo/service/event.service';
import { IconService } from './demo/service/icon.service';
import { NodeService } from './demo/service/node.service';
import { PhotoService } from './demo/service/photo.service';

import { LoggerModule, NgxLoggerLevel } from "ngx-logger";

import {AngularFireModule} from "@angular/fire/compat";


import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { AuthHttpInterceptor, authHttpInterceptorFn, AuthModule } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { NotfoundComponent } from './layout/components/error/notfound/notfound.component';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { AuthInterceptor } from './services/auth0/AuthInterceptor';

//=== FINE IMPORT PRIMENG
export function playerFactory() {
    return player;
  }

@NgModule({
    declarations: [AppComponent],
    imports: [
        AppRoutingModule,
        AppLayoutModule,
        CommonModule,

        LottieModule.forRoot({player: playerFactory}),
        HttpClientModule,
        AngularFireModule.initializeApp(environment.firebase.latitude),
        LoggerModule.forRoot({
            level: NgxLoggerLevel.TRACE,
            serverLogLevel: NgxLoggerLevel.ERROR,
            disableConsoleLogging: false,
          }),
        AuthModule.forRoot({
            domain: environment.auth0.domain,
            clientId: environment.auth0.clientId,
            authorizationParams: {
              redirect_uri: window.location.origin,
              audience: environment.auth0.audience,
            },
              httpInterceptor: {
              allowedList: [
                //UTENTI
                environment.latitudeBE.apiUrl+"*",
                environment.gpcBE.apiUrl+"*",
              //  environment.latitudeBE.apiUrl+"utente/*",
                //CATASTO
                environment.catastaleBE.apiUrl+"cdu-config",
                environment.catastaleBE.apiUrl+"cdu-config/*",
                environment.catastaleBE.apiUrl+"mappale",
                environment.catastaleBE.apiUrl+"mappale/*",
               // " http://localhost:9091/api/v1/cdu-config"
               // "https://catastale-be.map-hosting.it/api/v1/cdu-config",
               // "https://catastale-be.map-hosting.it/api/v1/cdu-config/*"
               //"http://localhost:9091/api/v1/cdu-config"
                //APP
              ],
            }
          }),
        ],

    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
            CountryService,
            CustomerService,
            EventService,
            IconService,
            NodeService,
            PhotoService,
            ProductService,
            AuthHttpInterceptor,
            provideHttpClient(withInterceptors([authHttpInterceptorFn]))
            /* {
                provide: HTTP_INTERCEPTORS,
                useClass: AuthInterceptor,
                multi: true,
            }, */
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
