import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { LayoutService } from 'src/app/layout/service/app.layout.service';

@Component({
    selector: 'app-landing-home',
    templateUrl: './app.landing.component.html'
})
export class AppLandingComponent {

    constructor(
        public layoutService: LayoutService,
        public router: Router,
        public authService: AuthService,) { }
    login() {
        this.authService.loginWithRedirect().subscribe();
      }
}
