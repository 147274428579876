export const environment = {
    production: false,
  name: "TEST",
  //apiUrl: "https://ates-be-test.map-hosting.it/api/v1/",
  version: '0.0.34',
  latitudeBE:{
    apiUrl:"https://latitude-be.map-hosting.it/api/v1/",
  },
  gpcBE:{
    apiUrl: 'https://gpc-be.map-hosting.it/api/v1/',
  },
  creditiBE:{
    apiUrl:"https://geoservizi.crediti.map-hosting.it/api/v1/",
    token:"65dbea53-c80e-4357-b699-41dbbc025839"
  },
  geoBE:{
    apiUrl:"https://catastale-geo-be.map-hosting.it",
    token:"65dbea53-c80e-4357-b699-41dbbc025839"
  },
  catastaleBE:{
    apiUrl:"https://catastale-be.map-hosting.it/api/v1/",
    apiKey:"eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IkRoc1Y0U2RtdllIWUxnQVRkTGhpQSJ9",
    auth0:{
        clientId: "LkmOYdwmVY1ZXMP6Pm6SqE33RQxyXOle",
        domain: "gestore-catastale.eu.auth0.com",
        audience: "https://catastale-be.map-hosting.it",
    }
  },
  geocoderBE:{
    apiUrl: 'https://geocoder-be.map-hosting.it/api/public/geocoder',
  },
  captchaProxy:{
    url:"https://catasto-proxy.map-hosting.it"
  },
  catastoWMS:{
    apiUrl:"https://wms.cartografia.agenziaentrate.gov.it/inspire/ajax/ajax.php?",
    wmsUrl:" https://wms.cartografia.agenziaentrate.gov.it/inspire/wms/ows01.php?"
  },
  csvBox:{
    key:'qUNC7BeA0KOzxn2qARqfAPwp2kx9MF',
    secret_crea_richieste_pc:'4qWljzqjjHR19TV3NtTMSK1sY1Atb5',
    secretRichiestaImprese:'6UfJOE5dNOn5yum6AjcAtz6kMV4hp4',
    secretImportMassivoMappali:'yVM2dFGOIjxc47NxPLt9k8s8tWlLpK',
    user:'default123'
 },
  auth0: {
    clientId: "41NIzLKJHHrj5UD2rIndZkLdajuGIO5R",
    domain: "latitude.eu.auth0.com",
    audience: "https://latitude-be.map-hosting.it",
    redirectUri: window.location.origin,
  },
  openAPI:{
    oauthUrl:   'https://oauth.openapi.it/',
    username:   'operations.vertical@gmail.com',
    apiKey:     '8x1kmudr82wxaretmoqcyxfjok8fwndh'
  },
  firebase: {
    latitude:{
        apiKey: "AIzaSyAp7nmISofTPTM1A-_AqQ-ce6D_GUDuy1E",
        authDomain: "latitude-435208.firebaseapp.com",
        projectId: "latitude-435208",
        storageBucket: "latitude-435208.appspot.com",
        messagingSenderId: "450019186338",
        appId: "1:450019186338:web:5bd64a108f000aba2ea9fb",
        measurementId: "G-QM34QW0V0C",
        collection_crediti:"crediti"
    },
    geocoder:{
        apiKey: "AIzaSyBlL_d_PzfvmosUpyeHtuuuuVBHUicIKNQ",
        authDomain: "geocoder-376606.firebaseapp.com",
        projectId: "geocoder-376606",
        storageBucket: "geocoder-376606.appspot.com",
        messagingSenderId: "20663415101",
        appId: "1:20663415101:web:feb0ef03d45b90a0d6d554",
        measurementId: "G-XEL54HZXRC",
        collection:"civici_vertical",
        storageDir:"civici_vertical"
    },
    catastale:{
        apiKey: "AIzaSyBGeQqMn9HQVJohPQjF0SKZ9Rj92_9F6V8",
        authDomain: "gestore-catastale.firebaseapp.com",
        projectId: "gestore-catastale",
        storageBucket: "gestore-catastale.appspot.com",
        messagingSenderId: "172364245501",
        appId: "1:172364245501:web:d7090a7aff00b7f21c5194",
        measurementId: "G-8RDZG8RH79",
        collectionRichiesteVisura:"richieste_visura",
        collectionProspettiCatastali:"prospetti_catastali",
        collectionRichiesteProspettiCatastali:"richieste_prospetto_catastale",
    }
  },


};
