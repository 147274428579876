<div class="layout-wrapper" [ngClass]="containerClass">
    <app-topbar></app-topbar>
    <div class="layout-sidebar" *ngIf="authService.user$ | async as user">
        <app-sidebar></app-sidebar>
    </div>
    <ng-container *ngIf="authService.user$ | async as user; else showLanding">
        <div class="layout-main-container">
            <div class="layout-main">
                <router-outlet></router-outlet>
            </div>
            <app-footer></app-footer>
        </div>
        <div class="layout-mask"></div>
    </ng-container>
    <ng-template #showLanding>
        <app-landing-home></app-landing-home>

    </ng-template>
</div>
